import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const api = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL,
    prepareHeaders: (headers, { getState }) => {
      const token = getState().auth.userToken;
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
        return headers;
      }
    },
  }),
  reducerPath: "dietiamoApi",
  tagTypes: [
    "Guide",
    "Ricette",
    "RicetteDetails",
    "Tags",
    "Clienti",
    "ClientiDetails",
    "Visits",
    "MealPlans",
    "Referrals",
    "Meals",
    "Count",
    "Variance",
    "CountByMonth",
    "Password",
    "Advices",
    "MealTypes",
    "Nutritionist",
  ],
  endpoints: (build) => ({
    getGuide: build.query({
      query: () => ({
        url: "nutriboard/guides",
        method: "GET",
        providesTags: ["Guide"],
      }),
    }),
    getRicette: build.query({
      query: () => ({
        url: "nutriboard/recipes/?page=0&limit=100",
        method: "GET",
        providesTags: ["Ricette"],
      }),
    }),
    getRicetteDetails: build.query({
      query: (id) => ({
        url: `nutriboard/recipes/${id}`,
        method: "GET",
        providesTags: ["RicetteDetails"],
      }),
    }),
    deleteRicetta: build.mutation({
      query: (id) => ({
        url: `nutriboard/recipes/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: [{ type: "Ricette", id: "LIST" }],
    }),
    getTags: build.query({
      query: () => ({
        url: "nutriboard/recipes/tags",
        method: "GET",
        providesTags: ["Tags"],
      }),
    }),
    //TODO: fix null ingredient returned
    getIngredients: build.query({
      query: (ingredient) => ({
        url: `nutriboard/ingredients/?page=0&limit=10&text=${
          ingredient !== "" ? ingredient : "latte"
        }`,
        method: "GET",
      }),
    }),
    postRicetta: build.mutation({
      query: (data) => ({
        url: "nutriboard/recipes/",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Ricette"],
    }),
    getAllClients: build.query({
      query: () => ({
        url: "nutriboard/clients/?sort_by=name&order=ASC",
        method: "GET",
        providesTags: ["Clienti"],
      }),
    }),
    getClientDetails: build.query({
      query: (id) => ({
        url: `nutriboard/clients/${id}`,
        method: "GET",
        providesTags: ["ClientiDetails"],
      }),
    }),
    getVisits: build.query({
      query: (id) => ({
        url: `nutriboard/visits/?limit=100&page=0&client=${id}`,
        method: "GET",
        providesTags: ["Visits"],
      }),
    }),
    getVisitsDetails: build.query({
      query: (id) => ({
        url: `nutriboard/visits/${id}`,
        method: "GET",
        providesTags: ["Visits"],
      }),
    }),
    deleteVisits: build.mutation({
      query: (id) => ({
        url: `nutriboard/visits/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: [{ type: "Visits", id: "LIST" }],
    }),
    getMealPlans: build.query({
      query: (id) => ({
        url: `nutriboard/meal-plans/?clients_id=${id}&page=0&sort_by=name&order=ASC`,
        method: "GET",
        providesTags: ["MealPlans"],
      }),
    }),
    getReferrals: build.query({
      query: () => ({
        url: `nutriboard/nutritionists/referrals`,
        method: "GET",
        providesTags: ["Referrals"],
      }),
    }),
    postClient: build.mutation({
      query: (data) => ({
        url: "nutriboard/clients/",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Clienti"],
    }),
    putClient: build.mutation({
      query: ({ data, id }) => ({
        url: `nutriboard/clients/${id}`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: ["Clienti"],
    }),
    postVisit: build.mutation({
      query: (data) => ({
        url: "nutriboard/visits/",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Visits"],
    }),
    putVisits: build.mutation({
      query: ({ data, id }) => ({
        url: `nutriboard/visits/${id}`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: ["Visits"],
    }),
    deleteCliente: build.mutation({
      query: (id) => ({
        url: `nutriboard/clients/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: [{ type: "Clienti", id: "LIST" }],
    }),
    getMeals: build.query({
      query: () => ({
        url: "nutriboard/meals/",
        method: "GET",
        providesTags: ["Meals"],
      }),
    }),
    getCountClients: build.query({
      query: () => ({
        url: "nutriboard/nutritionists/clients-count/?interval=last-year",
        method: "GET",
        providesTags: ["Count"],
      }),
    }),
    getCountClientsFiltered: build.query({
      query: (active) => ({
        url: `nutriboard/nutritionists/clients-count/?interval=last-year&typology=${active}`,
        method: "GET",
        providesTags: ["Count"],
      }),
    }),
    getVariance: build.query({
      query: () => ({
        url: "nutriboard/nutritionists/clients-count-variation/",
        method: "GET",
        providesTags: ["Variance"],
      }),
    }),
    getClientsByMonths: build.query({
      query: () => ({
        url: "nutriboard/nutritionists/clients-count-by-month/",
        method: "GET",
        providesTags: ["CountByMonth"],
      }),
    }),
    getNutritionistSubscriptionInfo: build.query({
      query: ({ id }) => ({
        url: `nutriboard/nutritionists/${id}/subscription-info`,
        method: "GET",
        providesTags: ["Nutritionist"],
      }),
    }),
    postNewPassword: build.mutation({
      query: (data) => ({
        url: "nutriboard/auth/change-password",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Password"],
    }),
    putRicetta: build.mutation({
      query: ({ data, id }) => ({
        url: `nutriboard/recipes/${id}`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: ["Ricette"],
    }),
    postMealPlans: build.mutation({
      query: (data) => ({
        url: "nutriboard/meal-plans/",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["MealPlans"],
    }),
    postReminders: build.mutation({
      query: (data) => ({
        url: "nutriboard/reminders",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Advices"],
    }),
    putReminders: build.mutation({
      query: ({ mealPlanId, remindersId }) => ({
        url: `nutriboard/meal-plans/${mealPlanId}/advice/${remindersId}`,
        method: "PUT",
      }),
      invalidatesTags: ["Advices", "MealPlans"],
    }),
    postMeal: build.mutation({
      query: (data) => ({
        url: "nutriboard/meals/",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Meals"],
    }),
    getMealTypes: build.query({
      query: () => ({
        url: "nutriboard/meal-types/",
        method: "GET",
        providesTags: ["MealTypes"],
      }),
    }),
    postPasswordDimenticata: build.mutation({
      query: (data) => ({
        url: "nutriboard/auth/forgot-password",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Password"],
    }),
    postCodePassword: build.mutation({
      query: (data) => ({
        url: "nutriboard/auth/verify-reset-code",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Password"],
    }),
    postNewPasswordLogin: build.mutation({
      query: (data) => ({
        url: "nutriboard/auth/reset-password",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Password"],
    }),
    putNutritionist: build.mutation({
      query: ({ data, id }) => ({
        url: `nutriboard/nutritionists/${id}`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: ["Nutritionist"],
    }),
    postSendEmail: build.mutation({
      query: (data) => ({
        url: "nutriboard/send-emails",
        method: "POST",
        body: data,
      }),
    }),
    deleteMealPlans: build.mutation({
      query: (id) => ({
        url: `nutriboard/meal-plans/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: [{ type: "MealPlans", id: "LIST" }],
    }),
    checkReferralCode: build.mutation({
      query: (data) => ({
        url: `nutriboard/nutritionists/check-referral-code/`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Nutritionist"],
    }),
    putReferrals: build.mutation({
      query: ({ data }) => ({
        url: "nutriboard/nutritionists/referrals",
        method: "PUT",
        body: data,
      }),
      invalidatesTags: ["Nutritionist"],
    }),
    getMealPlanDetails: build.query({
      query: (mealPlanId) => ({
        url: `nutriboard/meal-plans/${mealPlanId}`,
        method: "GET",
        providesTags: ["MealPlans"],
      }),
    }),
    getMealPlanDays: build.query({
      query: ({ mealPlanId, dayId }) => ({
        url: `nutriboard/meal-plans/${mealPlanId}/days/${dayId}`,
        method: "GET",
        providesTags: ["MealPlans"],
      }),
    }),
    putMealPlans: build.mutation({
      query: ({ data, id }) => ({
        url: `nutriboard/meal-plans/${id}`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: ["MealPlans"],
    }),
    deleteMeals : build.mutation({
      query: (id) => ({
        url: `nutriboard/meals/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: [{ type: "Meals", id: "LIST" }],
    }),
    deleteBatchMeals: build.mutation({
      query: (ids) => ({
        url: `nutriboard/meals`,
        method: "DELETE",
        body: { "meal_ids": ids }
      }),
      invalidatesTags: [{ type: "Meals", id: "LIST" }],
    }),
    checkAvailableReferrerDiscount: build.query({
      query: () => ({
        url: "nutriboard/nutritionists/check-available-referrer-discount",
        method: "GET",
      }),
    }),
    postCloneMealPlans: build.mutation({
      query: (id) => ({
        url: `nutriboard/meal-plans/${id}`,
        method: "POST",
      }),
      invalidatesTags: ["MealPlans"],
    }),
    postAddRecipesToClient: build.mutation({
      query: ({ id, data }) => ({
        url: `nutriboard/clients/${id}/recipes`,
        method: "POST",
        body: data
      }),
      invalidatesTags: ["Clients"],
    }),
    getClientRecipeTags: build.query({
      query: (client_id) => ({
        url: `nutriboard/clients/${client_id}/recipes`,
        method: "GET",
        providesTags: ["Clients"],
      }),
    }),
    getActivityLevels: build.query({
      query: () => ({
        url: "nutriboard/activity-levels",
        method: "GET",
        providesTags: ["Visits"],
      }),
    }),
  }),
});

export const {
  useGetGuideQuery,
  useGetRicetteQuery,
  useGetRicetteDetailsQuery,
  useDeleteRicettaMutation,
  useGetTagsQuery,
  useGetIngredientsQuery,
  usePostRicettaMutation,
  useGetAllClientsQuery,
  useGetClientDetailsQuery,
  useGetVisitsQuery,
  useDeleteVisitsMutation,
  useGetMealPlansQuery,
  useGetReferralsQuery,
  usePostClientMutation,
  usePostVisitMutation,
  useDeleteClienteMutation,
  useGetMealsQuery,
  useGetCountClientsQuery,
  useGetVarianceQuery,
  useGetClientsByMonthsQuery,
  useGetCountClientsFilteredQuery,
  usePostNewPasswordMutation,
  usePutRicettaMutation,
  usePostMealPlansMutation,
  usePostRemindersMutation,
  usePutRemindersMutation,
  usePostMealMutation,
  useGetMealTypesQuery,
  usePostPasswordDimenticataMutation,
  usePostCodePasswordMutation,
  usePostNewPasswordLoginMutation,
  usePutNutritionistMutation,
  usePutClientMutation,
  useGetVisitsDetailsQuery,
  useGetNutritionistSubscriptionInfoQuery,
  usePutVisitsMutation,
  usePostSendEmailMutation,
  useDeleteMealPlansMutation,
  useCheckReferralCodeMutation,
  usePutReferralsMutation,
  useGetMealPlanDetailsQuery,
  useLazyGetMealPlanDaysQuery,
  usePutMealPlansMutation,
  useDeleteMealsMutation,
  useDeleteBatchMealsMutation,
  useLazyCheckAvailableReferrerDiscountQuery,
  usePostCloneMealPlansMutation,
  usePostAddRecipesToClientMutation,
  useGetClientRecipeTagsQuery,
  useGetActivityLevelsQuery,
} = api;
