export const bd_dw = (gender, bicipital, anterior_axillary, tricipital, subscapular) => {
    return (gender === "male" ? 1.1599 : 1.1631) - (gender === "male" ? 0.0717 : 0.0632) * Math.log10(bicipital + anterior_axillary + tricipital + subscapular);
}

export const bd_jp_7p = (gender, age, pectoral, mid_axillary, anterior_axillary, abdominal, tricipital, subscapular, thigh) => {
    const a = gender === "male" ? 1.112 : 1.097;
    const b = gender === "male" ? 0.00043499 : 0.00046971;
    const c = gender === "male" ? 0.00000055 : 0.00000056;
    const d = gender === "male" ? 0.00028826 : 0.00012828;
    const sum_skinfolds = pectoral + mid_axillary + anterior_axillary + abdominal + tricipital + subscapular + thigh;
    return a - b * (sum_skinfolds) + c * (sum_skinfolds) ** 2 - d * age;
}

export const bd_jp_3p = (gender, age, pectoral, anterior_axillary, abdominal, tricipital, thigh) => {
    const a = gender === "male" ? 1.10938 : 1.0994921;
    const b = gender === "male" ? 0.0008267 : 0.0009929;
    const c = gender === "male" ? 0.0000016 : 0.0000016;
    const d = gender === "male" ? 0.0002574 : 0.0001392;
    const male_sum_skinfolds = pectoral + abdominal + thigh;
    const female_sum_skinfolds = anterior_axillary + tricipital + thigh;
    const male_bd_jp_3p = a - (b * (male_sum_skinfolds)) + (c * (male_sum_skinfolds) ** 2) - (d * age);
    const female_bd_jp_3p = a - (b * (female_sum_skinfolds)) + (c * ((female_sum_skinfolds) ** 2)) - (d * age)
    return gender === "male" ? male_bd_jp_3p : female_bd_jp_3p;
}

export const bd_thorland = (gender, pectoral, mid_axillary, anterior_axillary, abdominal, tricipital, subscapular, thigh, calf) => {
    const a = gender === "male" ? 1.1091 : 1.1046;
    const b = gender === "male" ? 0.00052 : 0.00059;
    const c = gender === "male" ? 0.00000032 : 0.0000006;
    const sum_skinfolfs = pectoral + mid_axillary + anterior_axillary + abdominal + tricipital + subscapular + thigh + calf
    return a - b * (sum_skinfolfs) + c * (sum_skinfolfs) ** 2;
}

export const bd_lohman = (abdominal, tricipital, subscapular) => {
    const sum_skinfolds = abdominal + tricipital + subscapular;
    return 1.0982 - 0.000815 * (sum_skinfolds) + 0.00000084 * (sum_skinfolds) ** 2;
}

export const bd_katch = (abdominal, tricipital, subscapular) => {
    return 1.09665 - (0.00103 * tricipital) - (0.00056 * subscapular) - (0.00054 * abdominal);
}

export const bd_sloan = (subscapular, thigh) => {
    return 1.1043 - (0.001327 * thigh) - (0.00131 * subscapular);
}

export const bd_forsyth = (mid_axillary, abdominal, tricipital, subscapular) => {
    return 1.10647 - (0.00162 * subscapular) - (0.00144 * abdominal) - (0.00077 * tricipital) + (0.00071 * mid_axillary);
}

// da usare con bd_dw, bd_jp_7p, bd_jp_3p, bd_thorland, bd_lohman e bd_forsyth
export const percentage_siri = (bd) => {
    return ((4.95 / bd) - 4.5) * 100;
}
// da usare con bd_katch e bd_sloan
export const percentage_brozek = (bd) => {
    return ((4.57 / bd) - 4.142) * 100;
}


export const percentage_evans_3p = (gender, ethnicity, pectoral, anterior_axillary, abdominal, tricipital, thigh) => {
    const sum_skinfolds = gender === "male" ? pectoral + abdominal + thigh : tricipital + anterior_axillary + abdominal;
    return 8.997 + (0.24658 * (sum_skinfolds)) - (6.343 * (gender === "male" ? 1 : 0)) - (1.998 * (ethnicity === "african_american" ? 1 : 0));
}

export const percentage_slaughter = (gender, tricipital, calf) => {
    const a = gender === "male" ? 0.735 : 0.61;
    const b = gender === "male" ? 1 : 5.1;
    return a * (tricipital + calf) + b;
}

export const sum_skinfolds_body = (pectoral, mid_axillary, midclavicular, anterior_axillary, abdominal, subscapular) => {
    return pectoral + mid_axillary + midclavicular + anterior_axillary + abdominal + subscapular;
}

export const sum_skinfolds_limbs = (bicipital, tricipital, thigh, calf) => {
    return bicipital + tricipital + thigh + calf;
}

export const sum_skinfolds_leg = (thigh, calf) => {
    return thigh + calf;
}

export const sum_skinfolds_arm = (bicipital, tricipital) => {
    return bicipital + tricipital;
}

export const sum_skinfolds = (pectoral, mid_axillary, midclavicular, anterior_axillary, abdominal, subscapular, bicipital, tricipital, thigh, calf) => {
    return pectoral + mid_axillary + midclavicular + anterior_axillary + abdominal + subscapular + bicipital + tricipital + thigh + calf;
}

export const percentage_franco = (sum_skinfolds) => {
    return sum_skinfolds / 7.9;
}

export const fm = (percentage_franco, weight) => {
    return (percentage_franco / 100) * weight;
}

export const ffm = (fm, weight) => {
    return weight - fm;
}

//circonferenze
//Indice di rischio cardio vascolare (rapport vita/fianchi)
export const whr = (waist, hips) => {
    return waist / hips;
}

// bia
export const calculate_ffm_athletes = (height, weight, resistance, reactance, age) => {
    const height_squared = height ** 2;
    const ffm = -7.729 + (weight * 0.686) + (height_squared / resistance * 0.227) + (reactance * 0.086) + (age * 0.058);
    return ffm;
}


export const calculate_ffm_lohman_general = (height, resistance, weight) => {
    const height_squared = height ** 2;
    const ffm_lohman_general = 0.73 * (height_squared / resistance) + 0.16 * weight + 2.0;
    return ffm_lohman_general;
}
export const calculate_tbw_sun = (gender, height, resistance, weight) => {
    const height_squared = height ** 2;
    const a = gender === "male" ? 1.203 : 3.747;
    const b = gender === "male" ? 0.449 : 0.450;
    const c = gender === "male" ? 0.176 : 0.113;
    const ffm_men = a + b * height_squared / resistance + c * weight;
    return ffm_men;
}


//Function for overweight/obese FFM
export const calculate_ffm_overweight = (gender, height, weight, resistance, age) => {
    const height_squared = height ** 2;
    const a = gender === "male" ? 14.5244 : 9.3794;
    const b = gender === "male" ? 0.000886 : 0.000912;
    const c = gender === "male" ? 0.02299 : 0.01466;
    const d = gender === "male" ? 0.42688 : 0.2999;
    const e = gender === "male" ? 0.07002 : 0.07012;

    return a + b * height_squared - c * resistance + d * weight - e * age;
}


// Function for ECW Sergi
export const calculate_ecw_sergi = (gender, height, weight, resistance, reactance, health_status = 1) => {
    const height_squared = height ** 2;
    const gender_factor = gender === "male" ? 1 : 0;
    const metric = (-5.22
        + 0.20 * height_squared / resistance
        + 0.005 * height_squared / reactance
        + 0.08 * weight
        + 1.9 * health_status  // 1 for healthy, 2 for ill
        + 1.86 * gender_factor);
    return metric;
}

//Metabolismo basale
//TODO controllare che sia in kg e in cm
export const calculate_eer = (gender, height, weight, age) => {
    const a = gender === "male" ? 66.4730 : 665.0955;
    const b = gender === "male" ? 13.7516 : 9.5634;
    const c = gender === "male" ? 5.0033 : 1.8496;
    const d = gender === "male" ? 6.7759 : 4.6756;
    return a + b * weight + c * height - d * age
}

export const calculate_bmi = (height, weight) => {
    const bmi = (weight / ((height / 100) * (height / 100)));
    return bmi
}

export const calculate_tdee = (eer, laf) => {
    const tdee = eer * laf;
    return tdee
}